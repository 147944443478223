<template>
    <div class="section-wrapper">
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <template>
                      <div class="mb-2 d-flex justify-content-end"><b-button variant="primary" @click="pdfExport" size="sm"><i class="ri-printer-fill text-right"></i>{{
                      $t('globalTrans.export_pdf') }} </b-button></div>
                  </template>
                  <template>
                      <div style="font-size:12px; font-color:white; background-color: #dddddd; padding:6px">
                          <h5 class="card-title text-center" style="margin-bottom: 0;color: #214162;font-size: 1.40rem;"> {{ $t('teaGardenConfig.bought_leaf_factory') }} {{ $t('globalTrans.details') }}</h5>
                      </div>
                  </template>
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.registration_no')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ EngBangNum(item.registration_no) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.factory_name')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.factory_name_bn : item.factory_name_en }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.establishment_year')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ $n(item.year, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.select_made_tea_type')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getMadeTypeNames(JSON.parse(item.made_tea_type_id)) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.factory_type')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getFactoryType(item.factory_type_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.area_type')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getAreaType(item.area_type_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.division')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getDivisionName(item.division_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.district')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getDistrictName(item.district_id) }}</td>
                    </tr>
                    <tr v-if="item.upazila_id">
                      <th style="width: 10%"> {{$t('teaGardenConfig.upazila')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getUpazilaName(item.upazila_id) }}</td>
                    </tr>
                    <tr v-if="item.union_id">
                      <th style="width: 10%"> {{$t('teaGardenConfig.union')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getUnionList(item.union_id) }}</td>
                    </tr>
                    <tr v-if="item.city_corporation_id">
                      <th style="width: 10%"> {{$t('teaGardenConfig.city_corporation')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getCityCorporationList(item.city_corporation_id) }}</td>
                    </tr>
                    <tr v-if="item.pauroshoba_id">
                      <th style="width: 10%"> {{$t('teaGardenConfig.municipality_name')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getPauroshobaList(item.pauroshoba_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.has_own_garden')}}</th>
                      <th style="width: 2%">:</th>
                      <td v-if="item.has_own_garden === 1" style="width: 30%">{{ ($i18n.locale === 'bn') ? 'হ্যাঁ' : 'yes' }}</td>
                      <td v-else style="width: 30%">{{ ($i18n.locale === 'bn') ? 'না' : 'no' }}</td>
                    </tr>
                    <tr :style= "[item.garden_id === 0 ? {'display': 'none'} : {'display': 'show'}]">
                      <th style="width: 10%"> {{$t('teaGardenConfig.garden_name')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getGardenName(item.garden_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.prod_capacity')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ getProductionCapacityInfo(item.prod_capacity) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.land_area')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ $n(item.land_area, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.address')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.address_bn : item.address_en }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.description')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.description_bn : item.description_en }}</td>
                    </tr>
                    <tr v-if="item.attachment">
                      <th style="width: 10%"> {{$t('teaGardenPanel.attachment')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">
                        <a target="_blank" style = "margin-left: 8px;" :href="teaGardenServiceBaseUrl + (isImage(item.attachment) ? 'storage/' : '') + item.attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { boughtLeafFactoryDetails } from '../../api/routes'
import { mapGetters } from 'vuex'
export default {
    name: 'Details',
    props: ['id'],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        valid: null,
        loading: false,
        item: {},
        made_tea_type_id: 2,
        factory_type_id: 2,
        garden_id: 0,
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        city_corporation_id: 0,
        union_id: 0,
        pauroshoba_id: 0,
        errors: []
      }
    },
    created () {
        const tmp = this.getBoughtLeafEntry()
        this.item = tmp
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      EngBangNum (n) {
          if (this.$i18n.locale === 'bn') {
              return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
          } else {
              return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
          }
        },
      getDivisionName  (id) {
        const division = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
          if (division && this.$i18n.locale === 'bn') {
             return division.text_bn
          } else if (division && this.$i18n.locale === 'en') {
              return division.text_en
          }
      },
      getDistrictName (id) {
        const district = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
            if (district && this.$i18n.locale === 'bn') {
                return district.text_bn
            } else if (district && this.$i18n.locale === 'en') {
                return district.text_en
            }
      },
      getUpazilaName (id) {
        const upazila = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
            if (upazila && this.$i18n.locale === 'bn') {
                return upazila.text_bn
            } else if (upazila && this.$i18n.locale === 'en') {
                return upazila.text_en
            }
        },
        getUnionList (id) {
        const unoin = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
            if (unoin && this.$i18n.locale === 'bn') {
                return unoin.text_bn
            } else if (unoin && this.$i18n.locale === 'en') {
                return unoin.text_en
            }
        },
        getCityCorporationList (id) {
        const cityCorporation = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
            if (cityCorporation && this.$i18n.locale === 'bn') {
                return cityCorporation.text_bn
            } else if (cityCorporation && this.$i18n.locale === 'en') {
                return cityCorporation.text_en
            }
        },
        getPauroshobaList (id) {
        const pauroshoba = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
            if (pauroshoba && this.$i18n.locale === 'bn') {
                return pauroshoba.text_bn
            } else if (pauroshoba && this.$i18n.locale === 'en') {
                return pauroshoba.text_en
            }
        },
      getBoughtLeafEntry () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      getGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
          if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          }
      },
      getFactoryType (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterFactoryTypeList.find(item => item.value === id)
          if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          }
      },
      getAreaType (id) {
        const obj = this.$store.state.commonObj.areaTypeList.find(item => item.value === id)
          if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          }
      },
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.id)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
          if (service !== undefined) {
              if (service.office_type_id) {
                  params.office_type_id = service.office_type_id
              }
              if (service.office_id) {
                  params.office_id = service.office_id
              }
          }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, boughtLeafFactoryDetails + '/' + this.id, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
      getMadeTypeNames (data) {
      var madeTeaTypes = []
        if (Array.isArray(data)) {
          data.forEach(arrItem => {
          const item = this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.find(item => item.value === arrItem)
          if (item && this.$i18n.locale === 'bn') {
            madeTeaTypes.push(item.text_bn)
          } else if (item && this.$i18n.locale === 'en') {
            madeTeaTypes.push(item.text_en)
          }
          })
        }
        return madeTeaTypes.join(', ')
      },
      getProductionCapacityInfo (id) {
        var productionCapacity
        if (this.authUser.user_type === 1) {
            productionCapacity = this.$store.state.TeaGardenService.commonObj.masterProductionCapacityList
        } else {
            productionCapacity = this.$store.state.ExternalUserService.teaGarden.commonObj.masterProductionCapacityList
        }
        const obj = productionCapacity.find(item => item.value === id)
          if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          }
      },
      isImage (path) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
      }
    }
}
</script>
